<template>
	<section>
		
		<div class="container">
			
			<div class="container  mx-auto text-center"><!-- pricing-header  -->
				<div class=" text-center">
						<div style="font-size: 13px;margin-top:50px;
                color: #ff5757;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-bottom: 5px;text-align:center">  👋 Boarding time, get started with Ohmylead</div>
				<h2 class="get-started">Before going any further, we would recommend you check out our walkthrough video
</h2><br>
						<center>
							<div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_tuw4n17upk videoFoam=true" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/tuw4n17upk/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>
						</center>
						<br>
						<div>
						<h3 class="get-started ">Please login to the Desktop version to connect your lead sources or choose a time for yourself in the calendar below. You’ll get a quick review of the app, helping you to set up everything quickly.</h3>
						<a style="color:#fff" onclick="Calendly.showPopupWidget('https://calendly.com/oml-support/1-on-1-demo-ohmylead');return false;"  href="#" class="get-started-btn"> BOOK A FREE LIVE DEMO!</a>
					
						</div>
						
						
					</div>
				
			</div>
			
		
		</div>
		
	</section>
</template>
<script>
	export default {
		data () {
			return {
				interval: true,
			}
		}
	}
</script>
<style lang="scss">

	.box-shadow-md {
		border-radius: 0px;
		box-shadow: 0px 0px 4px #e4e4e4;
		-webkit-transition: all 0.25s ease;
		-o-transition: all 0.25s ease;
		transition: all 0.25s ease;
		
		.card-header {
			background-color: #17a2b8;
			color: #fff;
			border-radius: 0px !important;
			border-color: #17a2b8;
		}
		
		.card-body {
			.pricing-card-title {
				color: #17a2b8;
			}
			.list-unstyled {
				min-height: 15em;
				li {
					.fa-check {
						color: #3fab91;
					}
					
					.title-plan {
						color: #fff;
						font-size: 14px;
						padding: 4px 15px;
						background: #ff5870;
						border-radius: 3px;
					}
					
					padding: 8px;
					/* border-bottom: 1px solid #ccc; */
				}
				li:nth-child(even) {
					background: rgba(0, 0, 0, 0.08);
				}
			}
			
		}
		
		&:hover {
			-webkit-box-shadow: 0px 0px 15px #c7c7c7;
			box-shadow: 0px 0px 15px #c7c7c7;
			/* border: 1px solid white; */
		}
	}
	.lead-md {
		width		: 48%;
		font-size	: 18px;
		margin-left	: auto;
		margin-right: auto;
	}
    
</style>