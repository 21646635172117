<template>
    <div class="container">
        <!--div class="card card-default">
            <div class="card-header"><h4>{{ $t('welcomeMsg') }} OhMyLead</h4></div>
            <div class="card-body">
				<p class="title-md text-center">
                    Welcome
                </p>
            </div>
        </div-->
		<p class="title-md text-center mt-5 pt-5">
			{{$t('Welcome')}}
		</p>
    </div>
</template>
<script>
	export default {
		name: 'app',
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		computed: {
			
		},
		mounted() {
			this.$router.push({path: '/login'})
		},
		components: {
			
		},
	}
</script>
<style>
	.title-md {
		font-size: 84px;
		color: #636b6f;
		font-family: cursive, sans-serif;
		font-weight: 200;
	}
</style>